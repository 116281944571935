<template>
  <section class="page page-auth">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block-auth">
            <h2>Авторизация</h2>
            <form class="auth-form" @submit.prevent="submitHandler">
              <div class="form-group">
                <input
                  v-model.trim="v$.username.$model"
                  class="form-control"
                  name="login"
                  placeholder="Логин или email"
                  type="text"
                />
                <small
                  v-if="v$.username.$dirty && v$.username.minLength.$invalid"
                >
                  Длина логина должна быть от
                  {{ v$.username.minLength.$params.min }} символов
                </small>
                <small
                  v-if="v$.username.$dirty && v$.username.maxLength.$invalid"
                >
                  Длина логина должна быть не более
                  {{ v$.username.maxLength.$params.max }} символов
                </small>
              </div>
              <div class="form-group">
                <input
                  v-model.trim="v$.password.$model"
                  class="form-control"
                  name="password"
                  placeholder="Пароль"
                  type="password"
                />
                <small
                  v-if="v$.password.$dirty && v$.password.minLength.$invalid"
                >
                  Длина пароля должна быть от
                  {{ v$.password.minLength.$params.min }} символов
                </small>
                <small
                  v-if="v$.password.$dirty && v$.password.maxLength.$invalid"
                >
                  Длина пароля должна быть не более
                  {{ v$.password.maxLength.$params.max }} символов
                </small>
              </div>
              <div class="bottom">
                <button class="button">Войти в аккаунт</button>
                <p>
                  <router-link to="/register"
                    >или зарегистрироваться
                  </router-link>
                </p>
                <p>
                  <router-link to="/forgotPassword">забыл пароль</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";

export default {
  name: "Login",
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    username: "",
    password: "",
    signInWithGithubUrl: process.env.VUE_APP_GITHUB_OAUTH_URL,
    signInWithGmailUrl: process.env.VUE_APP_GMAIL_OAUTH_URL
  }),
  validations: {
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32)
    },
    password: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(64)
    }
  },
  methods: {
    async submitHandler() {
      if (this.v$.$invalid) {
        this.v$.$touch();
        return;
      }

      let formData = {
        username: this.username,
        password: this.password
      };

      try {
        await this.$store.dispatch("login", formData);
        await this.$store.dispatch("FETCH_USER", formData);
        await this.$router.push("/");
      } catch (e) {
        // nop
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-auth.scss";
</style>
